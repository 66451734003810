<template>
  <div class="home">
    <main class="">
      <div class="s-main-container">
        <Sidebar
          :nama_user="nama_user"
          :role_user="role_user"
          :main_items="chooseItems()"
        />
        <div class="s-ht-100-vh">
          <router-view></router-view>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Sidebar from "../components/dashboard/sidebar";
import Footer from "../components/dashboard/footerCMS";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CMS",
  components: {
    Sidebar,
    Footer,
  },
  data: () => ({
    nama_user: "King Arkin",
    role_user: "Admin",
    //Sidebar menu
    main_items_admin: [
      {
        text: "sidebar.home",
        url_icon: "home.svg",
        sd_item_selected: false,
        to: "Home Public",
      },
      {
        text: "sidebar.dashboard",
        url_icon: "grid.svg",
        sd_item_selected: false,
        to: "Dashboard CMS",
      },
      {
        text: "sidebar.profile",
        url_icon: "people.svg",
        sd_item_selected: false,
        children: [
          {
            name: "sidebar.userProfile",
            sub_item_selected: false,
            to: "New Users",
            params: { inputType: "User", userType: "Profile" },
          },
          {
            name: "sidebar.changePassword",
            sub_item_selected: false,
            to: "Change Password",
          },
        ],
      },
      {
        text: "sidebar.resourceDatabase",
        url_icon: "server.svg",
        sd_item_selected: false,
        to: "BRRD",
      },
      {
        text: "sidebar.feedbackCollection",
        url_icon: "chat.svg",
        sd_item_selected: false,
        to: "CFC",
      },
      {
        text: "sidebar.actionPlanMonitoring",
        url_icon: "slider.svg",
        sd_item_selected: false,
        to: "APMA",
      },
      {
        text: "sidebar.educationalContent",
        url_icon: "book-open.svg",
        sd_item_selected: false,
        to: "Edc Content",
      },
      {
        text: "sidebar.eventPublication",
        url_icon: "list.svg",
        sd_item_selected: false,
        to: "Event Publication",
      },
      {
        text: "sidebar.expertRequest",
        url_icon: "grid.svg",
        sd_item_selected: false,
        to: "Expert Request",
      },
      {
        text: "sidebar.expertMatching",
        url_icon: "chat.svg",
        sd_item_selected: false,
        to: "Expert Matching Dashboard",
      },
      {
        text: "sidebar.masterData",
        url_icon: "inbox.svg",
        sd_item_selected: false,
        children: [
          {
            name: "sidebar.expertise",
            sub_item_selected: false,
            to: "Expertise",
          },
          {
            name: "sidebar.stakeholders",
            sub_item_selected: false,
            to: "Stakeholders",
          },
          {
            name: "sidebar.experts",
            sub_item_selected: false,
            to: "Experts",
          },
          {
            name: "sidebar.publicUsers",
            sub_item_selected: false,
            to: "Public Users",
          },
        ],
      },
    ],

    main_items_stakeholders: [
      {
        text: "sidebar.home",
        url_icon: "home.svg",
        sd_item_selected: false,
        to: "Home Public",
      },
      {
        text: "sidebar.dashboard",
        url_icon: "grid.svg",
        sd_item_selected: false,
        to: "Dashboard CMS",
      },
      {
        text: "sidebar.profile",
        url_icon: "people.svg",
        sd_item_selected: false,
        children: [
          {
            name: "sidebar.userProfile",
            sub_item_selected: false,
            to: "New Users",
            params: { inputType: "User", userType: "Profile" },
          },
          {
            name: "sidebar.changePassword",
            sub_item_selected: false,
            to: "Change Password",
          },
        ],
      },
      {
        text: "sidebar.resourceDatabase",
        url_icon: "server.svg",
        sd_item_selected: false,
        to: "BRRD",
      },
      {
        text: "sidebar.feedbackCollection",
        url_icon: "chat.svg",
        sd_item_selected: false,
        to: "CFC",
      },
      {
        text: "sidebar.actionPlanMonitoring",
        url_icon: "slider.svg",
        sd_item_selected: false,
        to: "APMA",
      },
      {
        text: "sidebar.educationalContent",
        url_icon: "book-open.svg",
        sd_item_selected: false,
        to: "Edc Content",
      },
      {
        text: "sidebar.eventPublication",
        url_icon: "list.svg",
        sd_item_selected: false,
        to: "Event Publication",
      },
      {
        text: "sidebar.expertMatching",
        url_icon: "chat.svg",
        sd_item_selected: false,
        children: [
          {
            name: "sidebar.expertMatchingRequest",
            sub_item_selected: false,
            to: "Expert Matching Dashboard",
          },
          {
            name: "sidebar.expertList",
            sub_item_selected: false,
            to: "Experts",
          },
        ],
      },
    ],

    main_items_experts: [
      {
        text: "sidebar.home",
        url_icon: "home.svg",
        sd_item_selected: false,
        to: "Home Public",
      },
      {
        text: "sidebar.dashboard",
        url_icon: "grid.svg",
        sd_item_selected: false,
        to: "Dashboard CMS",
      },
      {
        text: "sidebar.profile",
        url_icon: "people.svg",
        sd_item_selected: false,
        children: [
          {
            name: "sidebar.userProfile",
            sub_item_selected: false,
            to: "New Users",
            params: { inputType: "User", userType: "Profile" },
          },
          {
            name: "sidebar.changePassword",
            sub_item_selected: false,
            to: "Change Password",
          },
        ],
      },
      {
        text: "sidebar.resourceDatabase",
        url_icon: "server.svg",
        sd_item_selected: false,
        to: "BRRD",
      },
      {
        text: "sidebar.feedbackCollection",
        url_icon: "chat.svg",
        sd_item_selected: false,
        to: "CFC",
      },
      {
        text: "sidebar.actionPlanMonitoring",
        url_icon: "slider.svg",
        sd_item_selected: false,
        to: "APMA",
      },
      {
        text: "sidebar.educationalContent",
        url_icon: "book-open.svg",
        sd_item_selected: false,
        to: "Edc Content",
      },
      {
        text: "sidebar.eventPublication",
        url_icon: "list.svg",
        sd_item_selected: false,
        to: "Event Publication",
      },
      {
        text: "sidebar.expertMatchingList",
        url_icon: "chat.svg",
        sd_item_selected: false,
        children: [
          {
            name: "sidebar.availableOpportunity",
            sub_item_selected: false,
            to: "Expert Matching List",
          },
          {
            name: "sidebar.signedUpRequest",
            sub_item_selected: false,
            to: "Expert Matching Signed Up",
          },
        ],
      },
    ],

    main_items_public: [
      {
        text: "sidebar.home",
        url_icon: "home.svg",
        sd_item_selected: false,
        to: "Home Public",
      },
      {
        text: "sidebar.dashboard",
        url_icon: "grid.svg",
        sd_item_selected: false,
        to: "Dashboard CMS",
      },
      {
        text: "sidebar.profile",
        url_icon: "people.svg",
        sd_item_selected: false,
        children: [
          {
            name: "sidebar.userProfile",
            sub_item_selected: false,
            to: "New Users",
            params: { inputType: "User", userType: "Profile" },
          },
          {
            name: "sidebar.changePassword",
            sub_item_selected: false,
            to: "Change Password",
          },
        ],
      },
      {
        text: "sidebar.feedbackCollection",
        url_icon: "chat.svg",
        sd_item_selected: false,
        to: "CFC",
      },
      {
        text: "sidebar.educationalContent",
        url_icon: "book-open.svg",
        sd_item_selected: false,
        to: "Edc Content",
      },
      {
        text: "sidebar.eventPublication",
        url_icon: "list.svg",
        sd_item_selected: false,
        to: "Event Publication",
      },
      {
        text: "sidebar.expertMatching",
        url_icon: "chat.svg",
        sd_item_selected: false,
        children: [
          {
            name: "sidebar.expertMatchingRequest",
            sub_item_selected: false,
            to: "Expert Matching Dashboard",
          },
          {
            name: "sidebar.expertList",
            sub_item_selected: false,
            to: "Experts",
          },
        ],
      },
    ],

    main_items_superAdmin: [
      {
        text: "sidebar.home",
        url_icon: "home.svg",
        sd_item_selected: false,
        to: "Home Public",
      },
      {
        text: "sidebar.dashboard",
        url_icon: "grid.svg",
        sd_item_selected: false,
        to: "Dashboard CMS",
      },
      {
        text: "sidebar.expertRequest",
        url_icon: "list.svg",
        sd_item_selected: false,
        to: "Expert Request",
      },
      {
        text: "sidebar.resourceDatabase",
        url_icon: "server.svg",
        sd_item_selected: false,
        to: "BRRD",
      },
      {
        text: "sidebar.feedbackCollection",
        url_icon: "chat.svg",
        sd_item_selected: false,
        to: "CFC",
      },
      {
        text: "sidebar.actionPlanMonitoring",
        url_icon: "slider.svg",
        sd_item_selected: false,
        to: "APMA",
      },
      {
        text: "sidebar.educationalContent",
        url_icon: "book-open.svg",
        sd_item_selected: false,
        to: "Edc Content",
      },
      {
        text: "sidebar.eventPublication",
        url_icon: "list.svg",
        sd_item_selected: false,
        to: "Event Publication",
      },
      {
        text: "sidebar.expertMatching",
        url_icon: "chat.svg",
        sd_item_selected: false,
        to: "Expert Matching Dashboard",
      },
      {
        text: "sidebar.masterData",
        url_icon: "inbox.svg",
        sd_item_selected: false,
        children: [
          {
            name: "sidebar.expertise",
            sub_item_selected: false,
            to: "Expertise",
          },
          {
            name: "sidebar.stakeholders",
            sub_item_selected: false,
            to: "Stakeholders",
          },
          {
            name: "sidebar.experts",
            sub_item_selected: false,
            to: "Experts",
          },
          {
            name: "sidebar.publicUsers",
            sub_item_selected: false,
            to: "Public Users",
          },
          {
            name: "Admin",
            sub_item_selected: false,
            to: "Admins",
          },
        ],
      },
    ],
  }),
  mounted() {
    this.setUserData();
  },
  methods: {
    ...mapActions({
      setUserData: "auth/setUserData",
    }),
    chooseItems() {
      if (this.role_type === "admin") return this.main_items_admin;
      else if (this.role_type === "stakeholder")
        return this.main_items_stakeholders;
      else if (this.role_type === "expert") return this.main_items_experts;
      else if (this.role_type === "superadmin")
        return this.main_items_superAdmin;
      return this.main_items_public;
    },
  },
  computed: {
    ...mapGetters({
      role_type: "auth/getRole",
    }),
  },
};
</script>

<style scoped>
.home {
  background-color: #f7f7f7;
  background-image: url("https://www.transparenttextures.com/patterns/cutcube.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
</style>
